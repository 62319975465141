import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 0 px;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }

  #postfullembed {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
  }
`;

const Scatter: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>Scattertext</title>
    </Helmet>
    
    <Wrapper css={PageTemplate}>
    
      <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
        <div css={[outer, SiteNavMain]}>
          <div css={inner}>
            <SiteNav isHome={false} />
          </div>
        </div>
      </header>
      
      <main id="site-main" className="site-main" css={[SiteMain]}> {/*SiteMain,outer*/}
        <PostFullHeader className="post-full-header">
          <PostFullTitle className="post-full-title">Scattertext</PostFullTitle>
        </PostFullHeader>

        <PostFullContent id="postfullembed" className="post-full-content">
          <div>Please give the demo some time for loading</div>
          <iframe src='https://s3-eu-west-1.amazonaws.com/files.innerdoc.com/scatter_Buitenland_vs_Binnenland.html' height="800"  width="100%"></iframe>
        </PostFullContent>   
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default Scatter;
